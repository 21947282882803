import React from "react"
import PageHeader from "../components/common/PageHeader"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import HeaderFeaturedImage from "../components/common/HeaderFeaturedImage"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "../components/common/Link"
import SEO from "../components/seo"

export default function AboutUsPage() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 100 }) {
        content
        title
        disclaimer {
          disclaimerContent
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        activities {
          activity {
            nameActivity
            linkActivity
            descriptionActivity
            imageActivity {
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1.5)
                }
              }
            }
            logoActivity {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)
  const content = data.wpPage.content
  const { title, featuredImage, disclaimer } = data.wpPage
  const img = data.wpPage.featuredImage?.node.localFile.childImageSharp
  const activities = data.wpPage.activities.activity

  return (
    <Layout>
      <SEO title={title} />
      <div>
        {featuredImage && (
          <HeaderFeaturedImage featuredImage={featuredImage} title={title} />
        )}
        <div className="max-w-6xl mx-auto py-8 lg:py-16 px-4 lg:px-8">
          <div className="flex flex-col gap-8 lg:gap-16">
            {!featuredImage ? <PageHeader text={title} /> : null}
            {content ? (
              <div className="max-w-3xl  mx-auto flex flex-col xl:flex-row gap-4 lg:gap-8 items-center">
                <div className="flex-1">
                  <div
                    dangerouslySetInnerHTML={{ __html: content }}
                    className="wp-content"
                  />
                </div>
              </div>
            ) : null}
            <div
              id="activities"
              className="grid lg:grid-cols-3 gap-4 lg:gap-12"
            >
              {activities.map((activity, i) => {
                return <Activity key={i} activity={activity} />
              })}
            </div>
          </div>
          <div id="disclaimer" className="text-sm mt-24 italic">
            <div
              dangerouslySetInnerHTML={{ __html: disclaimer.disclaimerContent }}
              className="wp-content"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

function Activity({ activity }) {
  const { linkActivity, descriptionActivity, imageActivity, logoActivity } =
    activity
  const logo = logoActivity?.localFile.childImageSharp
  const img = imageActivity?.localFile.childImageSharp
  return (
    <div className="flex flex-col gap-4 items-center">
      {logo ? (
        <div className="w-1/3">
          {linkActivity ? (
            <Link to={linkActivity}>
              <GatsbyImage image={getImage(logo)} />
            </Link>
          ) : (
            <GatsbyImage image={getImage(logo)} />
          )}
        </div>
      ) : null}
      {/* <div className="flex items-center gap-4">
        <h3 className="text-2xl font-bold">{nameActivity}</h3>
      </div> */}
      {img ? (
        <div className="flex flex-col items-center justify-center">
          <GatsbyImage
            image={getImage(img)}
            className="rounded-full w-32 lg:w-48 lg:h-48 h-32  opacity-[99%]"
          />
        </div>
      ) : null}

      <div>
        <div
          dangerouslySetInnerHTML={{ __html: descriptionActivity }}
          className="wp-content"
        />
      </div>
    </div>
  )
}
